/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useEffect, useRef, useState } from 'react'

import { enquireScreen } from 'enquire-js'
import { DataSource } from '../../data'
import Subscribe from '../../Component/Subscribe'
import { get, post } from '../../http'
import { Skeleton, message, Spin } from 'antd'
import { useParams, Link, useNavigate } from 'react-router-dom'
import FilterImg from '../FilterImg'
import TableComponent from './table'
import LinkComponent from '../Link'

import DualAxesChart from '../Chat'
import './index.less'
message.config({
    top: 100
})

let isMobile
enquireScreen((b) => {
    isMobile = b
})

function App(props) {

    const [dataSource, setDataSource] = useState({})

   
    return (
        <div className="explore-content">
            <div className='explore-title'>Explore the Index</div>
            <div className='explore-tip'>The Solactive Etna Artificial General Intelligence Index(SOLEAGIX) is designed to track the performance of a selection of public companies that are primarily benefiting from the rise of Generative AI and will play crucial roles in this new technology wave. As the co-developer of the Index, the Etna team is establishing a benchmark for the rapid adoption of Generative AI.</div>
            <div className='explore-tip1'>Index Performance</div>
            <div className='explore-chat'>
              <DualAxesChart isShowIcon={true} onChange={setDataSource} isMobile={isMobile} />
            </div>
            <div className='explore-chat_tip'>
                    Click <LinkComponent text={"Here"} />, Get Complete Index Information
                </div>
          

            <TableComponent  dataSource={dataSource}/>

        </div>

    )
}

export default App
