/**
 * @description menu entry
 * @author wangfupeng
 */

import BulletedListMenu from './BulletedListMenu'
import NumberedListMenu from './NumberedListMenu'

export const bulletedListMenuConf = {
  key: 'bulletedList-new',
  factory() {
    return new BulletedListMenu()
  },
}

export const numberedListMenuConf = {
  key: 'numberedList-new',
  factory() {
    return new NumberedListMenu()
  },
}
