/**
 * @description parse html
 * @author wangfupeng
 */

import $, { DOMElement } from '../utils/dom'
import { DomEditor, IDomEditor, SlateEditor, SlateText, SlateTransforms } from '@wangeditor/editor'

function parseHtml(
  elem,
  children,
  editor
) {
  const $elem = $(elem)

  children = children.filter(child => {
    if (SlateText.isText(child)) return true
    if (editor.isInline(child)) return true
    return false
  })

  // 无 children ，则用纯文本
  if (children.length === 0) {
    children = [{ text: $elem.text().replace(/\s+/gm, ' ') }]
  }

  return {
    type: 'preface',
    // @ts-ignore
    children,
  }
}

export const parseHtmlConf = {
  selector: 'preface:not([data-w-e-type])', // data-w-e-type 属性，留给自定义元素，保证扩展性
  parseElemHtml: parseHtml,
}
