import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route,Navigate  } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from './Home/Nav3';
import Footer from './Home/Footer1';
import Home from './Home';
import About from './About';
import Team from './Team';
import Temp from './Temp';
import KnowledgeBank from './KnowledgeBank';
import Philosophy from './Philosophy'
import PhilosophyItem from './PhilosophyNew'
import enUS from 'antd/lib/locale/en_US'
import zhCN from 'antd/lib/locale/zh_CN';
import { IntlProvider } from 'react-intl'
import zh_CNJS from './Locale/zh_CN' // import defined messages in Chinese
import en_USJS from './Locale/en_US'
import { ConfigProvider } from 'antd'
import {
  Nav30DataSource,
  Footer10DataSource,
} from './Home/data.source.js';
import Pubsub from 'pubsub-js'
import Agix from './Agix'

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      Lang: {
        zh_CN: zh_CNJS,
        en_US: en_USJS
      },
      language: 'en_US'
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    Pubsub.subscribe('language', async (res,lang) => {
      this.setState({ language: lang })
    })
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    var rootDiv = document.getElementById("root");

// 获取第一个div元素
var firstDiv = rootDiv.querySelector("div");

// 如果找到第一个div元素，删除它的style属性
if (firstDiv) {
  firstDiv.removeAttribute("style");
}
  }

  

  render() {
    return (
      <div style={{overflowX:'hidden'}}>
      <Router>
      <IntlProvider locale={en_USJS} messages={this.state.Lang[this.state.language] || en_USJS}>
        <Header id="Nav3_0"
          key="Nav3_0" dataSource={Nav30DataSource} isMobile={this.state.isMobile}  />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact" element={<About />} />
          <Route exact path="/research" element={<KnowledgeBank />} />
          <Route exact path="/products" element={<Temp />} />
          <Route exact path="/details/:id" element={<PhilosophyItem />} />
          <Route exact path="/research-item/:id" element={<PhilosophyItem />} />
          <Route exact path="/edit" element={<Team />} />
          <Route path="*" element={<Navigate to ="/" />}/>
        </Routes>
        {/* <Footer dataSource={Footer10DataSource} isMobile={this.state.isMobile} /> */}
      </IntlProvider>
     </Router>
     </div>
    );
  }
}

export default App;

