import React, { useEffect, useRef, useState } from 'react'

import { Button, message, Spin } from 'antd'
import { useNavigate , Link, useLocation } from 'react-router-dom';
import './index.less'
message.config({
    top: 100
})

function App({ menuList , isDark,isMobile}) {
    const location = useLocation();
    const navigate = useNavigate();
    const onChangeUrl = (url) => {
        console.log(url);
       if(url.indexOf('http')>-1) {
        window.open(url,"_blank");
       }else{
        navigate(url)
    };
    }
    return (
        <div className="menu">
            {menuList.map((item, index) => (<div onClick={()=>onChangeUrl(item.url)} className={`menu-btn menu-item-active ${location.pathname.indexOf(item.url) > -1 ? 'menuactive' : ''}`}>
                    <span style={{color: isDark?'#fff':'',fontWeight:isDark?location.pathname.indexOf(item.url) > -1?700:500:""}}>{item.name}</span>
                    <span className="line1" style={{bottom: isDark ? isMobile?'4px':'15px':''}}></span>
                </div>))}
        </div>
    )
}

export default App
