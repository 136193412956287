import React from 'react'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { Button } from 'antd'
import { getChildrenToRender } from './utils'

class Content11 extends React.PureComponent {
  render() {
    const { ...props } = this.props
    const { dataSource } = props
    delete props.dataSource
    delete props.isMobile
    return (
      <OverPack {...props} {...dataSource.OverPack}>
        <div className="project-block-bg"></div>
        <div style={{position: 'absolute',top: 0}} className="project-block">
          <div className="project-item bytedance"></div>
          <div className="project-item spacex"></div>
          <div className="project-item epic_games"></div>
          <div className="project-item discord"></div>
        </div>

        <div style={{zIndex:20}} className="project-block">
          <div className="project-item">
            <div className="bytedance-logo"></div>
          </div>
          <div className="project-item">
            <div className="spacex-logo"></div>
          </div>
          <div className="project-item">
            <div className="epic_games-logo"></div>
          </div>
          <div className="project-item">
            <div className="discord-logo"></div>
          </div>
        </div>


        <div className="project-block-notice">
           <div className='project-block-notice-title'>We invest in</div>
           <div className='project-block-notice-desc'>AI · Enterprise · Fintech · Gaming · Frontier-Tech…</div>
           <div className='project-block-notice-tip'>Some images here are sourced from the internet and belong to their respective owners. If you believe any of the images infringe upon your rights or are not properly attributed, please contact us directly, and we will promptly address the issue.</div>
        </div>

        {/* <TweenOne
          key="button"
          style={{ textAlign: 'center' }}
          {...dataSource.button}
          animation={{ y: 30, opacity: 0, type: 'from', delay: 300 }}
        >
    
        </TweenOne> */}
      </OverPack>
    )
  }
}

export default Content11
