/**
 * @description render elem
 * @author wangfupeng
 */


import { h, VNode } from "snabbdom";

/**
 * render block quote elem
 * @param elemNode slate elem
 * @param children children
 * @param editor editor
 * @returns vnode
 */
function renderBlockQuote(
  elemNode,
  children,
  editor
) {
  const containerVnode = h(
    'preface',
    {
      style:{
        padding:'5px 10px',
        display: 'block',
        'border': '1px solid #5777A7',
        'background-color': 'rgba(87,119,167,0.05)'
      }
    },
    children
  );
  return containerVnode
}

export const renderBlockQuoteConf = {
  type: 'preface',
  renderElem: renderBlockQuote,
}
