/**
 * @description to html
 * @author wangfupeng
 */


function quoteToHtml(elem, childrenHtml) {
  return `<preface>${childrenHtml}</preface>`
}

export const quoteToHtmlConf = {
  type: 'preface',
  elemToHtml: quoteToHtml,
}
