/**
 * @description block quote menu
 * @author wangfupeng
 */

import PrefaceMenuMenu from './prefaceMenu'

export const PrefaceMenuConf = {
  key: 'preface',
  factory() {
    return new PrefaceMenuMenu()
  },
}
