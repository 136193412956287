const Index = {
    'Nav30DataSource.Home':'主页',
    'Nav30DataSource.Philosophy':'理念',
    'Nav30DataSource.Team':'团队',
    'Nav30DataSource.KnowlegeBank':'知识库',
    'Nav30DataSource.Careers':'加入我们',
    'Banner50DataSource.title1':'创新 ',
    'Banner50DataSource.title2':'投资',
    'Banner50DataSource.title3':'范式',
    'Banner50DataSource.content':`拾象是创新投资范式的引领者，致力于打造下一代的全球投资平台，为客户提供最好的投资服务。`,
    'Feature70DataSource.title':' 全球另类投资的伙伴',
    'Feature70DataSource.content':'拾象作为一个全球投资平台，可以接触到广泛的另类资产，确定高质量的投资目标，并优先分配稀缺资源。',
    'Feature70DataSource.block1':'投资 DAO',
    'Feature70DataSource.block2':'全球投资平台',
    'Feature70DataSource.block31':'认知先进的一流',
    'Feature70DataSource.block32':'投资社区和网络',
    'Feature70DataSource.block4':'创始人互相扶持',
    'Content00DataSource.title':'市场定位',
    'Content001DataSource.title':'全球团队',
    'Content001DataSource.img':'global.png',
    'Content00DataSource.content':'拾象致力于推动信息和认知平权，赋予客户自主投资的能力。',
    'Content00DataSource.block1.title':'灵活性 & 信息和认知开放',
    'Content00DataSource.block2.title':'去中心化模式',
    'Content00DataSource.block3.title':'强洞察和强判断',
    'Content00DataSource.block1.content':'我们致力于重塑投资信息分发网络，打破传统基金模式的认知和信息垄断，推动信息和认知平权。',
    'Content00DataSource.block2.content':'我们相信区块链有潜力提供跨行业和超越加密的突破性解决方案。 ',
    'Content00DataSource.block3.content':'选择交易所时，信任很重要。 这就是为什么安全一直是并将永远是我们的首要考虑因素。',
    'Feature00DataSource.title':'关键数据',
    'Feature00DataSource.block1.title':'超 10 亿美元资金管理规模',
    'Feature00DataSource.block2.title':'20 余家独角兽级资产',
    'Feature00DataSource.block3.title':'前沿领域分析师',
    'Feature00DataSource.block4.title':'覆盖追踪全球超 100 家全球优秀基金',
    'Feature00DataSource.block5.title':'每年筛选超 200 家公司',
    'Feature00DataSource.block6.title':'拾象网络已覆盖超 80 位中国新经济企业家',
    'Teams00DataSource.title':'关于我们',
    'Teams00DataSource.content':'拾象科技成立于 2019 年底，由红杉资本投资人李广密发起，红杉中国孵化成立，致力于为成功企业家、新经济领袖和红杉中国成员打造具有长期竞争力的投资组合服务。',
    'Content50DataSource.title':'我们专注研究全球范围最优秀的前沿赛道和创新企业，投资能重塑行业的公司',
    'Content50DataSource.block1.title':'全球 SaaS',
    'Content50DataSource.block2.title':'全球 Fintech',
    'Content50DataSource.block3.title':'太空探索',
    'Content50DataSource.block4.title':'加密货币',
    'Content50DataSource.block5.title':'元宇宙 & 游戏',
    'Content50DataSource.block6.title':'生物科学',
    'Feature30DataSource.title':'投资即服务',
    'Feature30DataSource.content':'我们致力于使知识和见解民主化，使我们的客户能够做出独立的投资决策。',
    'Feature30DataSource.block1.title':'咨询',
    'Feature30DataSource.block2.title':'另类',
    'Feature30DataSource.block3.title':'流动',
    'Feature30DataSource.block4.title':'研究',
    'Feature30DataSource.block5.title':'生态',
    'Feature30DataSource.block6.title':'常青',
    'Feature30DataSource.block1.content':'持续二十年为行业领袖和创始人提供咨询服务',
    'Feature30DataSource.block2.content':'赋能创始人进行另类投资和科学资产配置',
    'Feature30DataSource.block3.content':'推动优秀认知和资产在不同阶段的创始人间流动',
    'Feature30DataSource.block4.content':'对指数增长的行业和公司进行深度研究',
    'Feature30DataSource.block5.content':'科学家和创始人自由分享知识的生态系统',
    'Feature30DataSource.block6.content':'为未来 20 年中最伟大的公司建立灵活和长远的投资工具',
    'Banner10DataSource.title':'投资战略',
    'Banner10DataSource.content':'我们与定义未来的行业领袖合作',
    'Banner10DataSource.block1.title':'成为一个高增长大型市场中的类别领导者',
    'Banner10DataSource.block21.title':'稳固的商业模式和可观的',
    'Banner10DataSource.block22.title':'单位经济效益',
    'Banner10DataSource.block3.title':'杰出的领导团队',
    'Feature00DataSource.P.title':'战略资本项目',
    'Feature00DataSource.P.content':'大型、创新和高增长市场的品类领导者',
    'Feature00DataSource.P.block1':'专项基金',
    'Feature00DataSource.P.block2':'母基金',
    'Feature00DataSource.P.block3':'主题基金',
    'Feature00DataSource.P.block4':'加密货币',
    'Feature00DataSource.P.block5':'账户管理',
    'Teams40DataSource.title':'认识我们的团队',
    'Teams40DataSource.content':'年轻和快速成长的创始团队，进行复杂交易操作，践行最佳实践',
    'Teams30DataSource.title':'关注海外独角兽微信公众号',
    'Teams30DataSource.content':'「海外独角兽」是开放的投研信息共享平台，致力于重塑投资信息分发网络，打破传统基金的信息和认知垄断，推动信息和认知平权，赋予企业家和创业者自主决策的能力',
    'Teams40DataSource.guangmi':'首席执行官',
    'Teams40DataSource.max':'联合创始人、业务拓展负责人',
    'Teams40DataSource.penny':'联合创始人、研究与内容负责人',
    'Teams40DataSource.yilong':'联合创始人、投资交易与合规负责人',
    'Teams40DataSource.jiahao':'联合创始人、技术负责人',
    'Teams40DataSource.yuanhong':'联合创始人、 Crypto 实验室负责人',
    'Teams40DataSource.liaokun':'投资副总裁',
    'Teams40DataSource.siqi':'投资研究副总裁',
    'Teams40DataSource.rita':'业务拓展副总裁',
    'Teams40DataSource.patrick':'战略计划经理',
    'Teams40DataSource.jessie':'分析师',
    'Teams40DataSource.kefei':'分析师',
    'Teams40DataSource.chengjia':'分析师',
    'Teams40DataSource.jingwen':'运营和招聘经理',
    'Teams40DataSource.shitong':'前端工程师',
    'Teams40DataSource.haoyu':'技术工程师',
    'Teams40DataSource.binlu':'财务主管',
    'Teams40DataSource.hangbin':'行政经理',

}
export default Index