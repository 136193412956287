import React, { useEffect, useState, useRef } from 'react'
import QueueAnim from 'rc-queue-anim'
import { Row, Col, Divider } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { getChildrenToRender, isImg } from './utils'
import { DataSource } from '../data'
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Skeleton, message, Spin } from 'antd'
import Subscribe from '.././Component/Subscribe'
import { get, post } from '../http'

function App(props) {
  const [list, setList] = useState([])
  const [visible, setVisble] = useState(false)
  const [firstData, setFirstData] = useState({
    banner:'',
    customTime:'',
    title:''
  })
  const [height, setHeight] = useState(500)
  const myRef = useRef(null);
  async function getList() {
    try {
      setVisble(true)

      let url1 = `https://dev2022.shixiangcap.com/sxfiles/etna/article/searchCount`;
      const response = await post(url1, {
        "counted": true,
        "orders": "order by createTime desc",
        "pageIndex": 0,
        "pageSize": 50,
        "wheres": [{
          "key": "publishedStatus",
          "operator": "=",
          "value": 1
        },{
          "key": "isFrontPage",
          "operator": "=",
          "value": 0
        }]
      })
      const response1 = await post(url1, {
        "counted": true,
        "orders": "order by createTime desc",
        "pageIndex": 0,
        "pageSize": 50,
        "wheres": [{
          "key": "publishedStatus",
          "operator": "=",
          "value": 1
        },{
          "key": "isFrontPage",
          "operator": "=",
          "value": 1
        }]
      })
      const _list = response.data.records
      const _list1= response1.data.records
      const item = _list1.length>0?_list1[0]:_list.length>0?_list[0]:{
        banner:'',
        customTime:'',
        title:'',
        uid:''
      }
      if (response.code !== 200) throw new Error(response.msg)
      const list = [..._list1,..._list].filter((i,j)=>{return i.uid!==item.uid})
      console.log(list);
      setList(list)
      setFirstData(item)
      setTimeout(()=>{
        setHeight(myRef.current.offsetHeight)
      },50)
      setVisble(false)
      return response
    } catch (e) {
      setVisble(false)
      return {
        success: false,
        message: e.message
      }
    }
  }


  useEffect(() => {
    getList()
  }, [])

  return (
    <div>

      <div className="research">
        <div>
          <div className='research-list-title'>ETNA Research</div>
          <div className='research-list-title_tip'>fueling the future, navigator for innovators</div>

          {visible && (
          <div style={{ maxWidth: '1200px', margin: 'auto' }}>
            <Skeleton paragraph={{ rows: 20 }} active />
          </div>
        )}
          {!visible && <div className='research-list-block'>
            {!props.isMobile && <Link  to={`/details/${firstData.uid}`} target="_blank"><div ref={myRef} className='research-list-item-left'>
              <img className='research-list-item-left-img' src={firstData.banner || ''} />
              
              <div className='research-list-item-left-info'>
                <div className='research-list-item-left-row'>
                  <span className='research-list-item-left-tag'>EDITOR'S CHOICE</span>
                  <span className='research-list-item-left-time'>{firstData.customTime || ''}</span>
                </div>
                <div className='research-list-item-left-title'>
                  { firstData.title || ''}
                </div>
              </div>
            
            </div>  </Link>}
            <div className='research-list-item-right'>
              <div className='research-list-item-right-tip'>Latest Posts</div>
              <div style={{height:`${height}px`,overflow:'auto'}}>

              {list.map((i, j) => {
                return <Link key={`${j}`} to={`/details/${i.uid}`} target="_blank">
                  <div className="research-list-item-right-item">

                    <div style={{marginRight:'10px'}}>
                      <div style={{marginBottom:'10px'}} className='research-list-item-left-tag'>RECOMMENDATION</div>
                      <div style={{marginBottom:'10px'}}  className='research-list-item-right-title'>{i.title}</div>
                      <div className='research-list-item-right-time'>{i.customTime}</div>
                    </div>
                    <div>
                     <img className='research-list-item-right-img' src={i.banner}></img>
                    </div>
                  </div>
                </Link>
              })}
              </div>
              {/* {list.map((i,j)=>{
              return  <Link key={`${j}`} to={`/details/${i.uid}`} target="_blank">
              <div className="research-list-item">
                    
                    <div className='research-list-item-text'>
                      <div className="research-list-item-icon "></div>
                      <div >
                        <div className="research-list-item-title ">
                        {i.title}
                        </div>
                        <div  className="research-list-item-time ">{i.customTime}</div>
                      </div>
                    </div>
                    <div className="research-list-item-img">
                      <img src={`${i.banner}`} />
                    </div>
                  </div>
              </Link>
            })} */}
            </div>

          </div>}
        </div>

      </div>
      <Subscribe />

    </div>

  )
}

export default App
