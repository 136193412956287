/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useEffect, useRef, useState } from 'react'

import { enquireScreen } from 'enquire-js'
import { DataSource } from '../data'
import Subscribe from '../Component/Subscribe'
import { get, post } from '../http'
import { Skeleton, message, Spin } from 'antd'
import { useParams, Link, useNavigate } from 'react-router-dom'
import MenuComponent from '../Component/Menu'
import ExploreComponent from './Explore'
import PhilosophyComponent from './Philosophy'
import CompaniesComponent from './Companies'
import ResourcesComponent from './Resources'
import FooterComponent from './Footer'
import MenuLeftComponent from "./Menu"

import PcComponent  from './pc'
import MobileComponent  from './mobile'
import './index.less'
message.config({
  top: 100
})

let isMobile
enquireScreen((b) => {
  isMobile = b
})

function App(props) {
 

  return (
    <>
    
       
      {isMobile ? <MobileComponent /> : <PcComponent />}
    </>

  )
}

export default App
