/**
 * @description blockquote entry
 * @author wangfupeng
 */

import { IModuleConf } from '@wangeditor/core'
import { renderBlockQuoteConf } from './render-elem'
import { quoteToHtmlConf } from './elem-to-html'
import { parseHtmlConf } from './parse-elem-html'
import { PrefaceMenuConf } from './menu/index'
import withBlockquote from './plugin'

const prefaceModule = {
  renderElems: [renderBlockQuoteConf],
  elemsToHtml: [quoteToHtmlConf],
  parseElemsHtml: [parseHtmlConf],
  menus: [PrefaceMenuConf],
  editorPlugin: withBlockquote,
}

export default prefaceModule
