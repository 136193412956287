const Index = {
    'Nav30DataSource.Home':'Home',
    'Nav30DataSource.Philosophy':'Philosophy',
    'Nav30DataSource.Team':'Team',
    'Nav30DataSource.KnowlegeBank':'Knowledge Bank',
    'Nav30DataSource.Careers':'Careers',
    'Banner50DataSource.title1':'An Innovative ',
    'Banner50DataSource.title2':'Investment',
    'Banner50DataSource.title3':' Paradigm',
    'Banner50DataSource.content': `Shixiang is redefining investment services. We provide best LP
    services for entrepreneurs and investors who want to participate
    in technical revolutions in the next generation.`,
    'Feature70DataSource.title':' Your partner for global alternative investments',
    'Feature70DataSource.content':'As a global investment platform, we have access to a wide range of diverse assets, identifying high-quality investment targets, and prioritizing the allocation of scarce resources.',
    'Feature70DataSource.block1':'An investment DAO',
    'Feature70DataSource.block2':'A global investment platform',
    'Feature70DataSource.block31':'An ecosystem of connections for the exchange of insights',
    'Feature70DataSource.block32':' ',
    'Feature70DataSource.block4':'Founders backing founders',
    'Content00DataSource.title':'Market Position',
    'Content001DataSource.title':'Global Team',
    'Content001DataSource.img':'global_en.png',
    'Content00DataSource.content':'Shixiang is committed to promoting information equality, and empowering customers to invest independently.',
    'Content00DataSource.block1.title':'Flexibility, Information Transparency and an Open Mind',
    'Content00DataSource.block2.title':'Decentralized Models',
    'Content00DataSource.block3.title':'Insights & Good Judgement',
    'Content00DataSource.block1.content':'We are committed to reshaping the investment information distribution network, breaking the information monopoly of the traditional fund model, and promoting information and cognitive equality.',
    'Content00DataSource.block2.content':'We believe in the potential of blockchain to provide groundbreaking solutions across industries and beyond crypto. ',
    'Content00DataSource.block3.content':'When choosing an exchange, trust matters. That’s why security has been and will always be our top consideration.',
    'Feature00DataSource.title':'Data highlights',
    'Feature00DataSource.block1.title':'Over $1B AUM',
    'Feature00DataSource.block2.title':'Global unicorn portfolios',
    'Feature00DataSource.block3.title':'Professionals',
    'Feature00DataSource.block4.title':'Funds in database',
    'Feature00DataSource.block5.title':'Companies screened each year',
    'Feature00DataSource.block6.title':'Founders networks to exchange insights',
    'Teams00DataSource.title':'About Us',
    'Teams00DataSource.content':'Shixiang was established at the end of 2019 by former Sequoia Capital investor Li Guangmi. The core team is from Sequoia China with a new generation of insights and expertise in growth investments. Backed by the investment of Sequoia China, Shixiang is dedicated to providing successful entrepreneurs, new economy leaders, and Sequoia China members with long-term competitive portfolio services.',
    'Content50DataSource.title':'We research and invest in future-oriented themes and companies bringing disruptive innovation.',
    'Content50DataSource.block1.title':'Global SaaS',
    'Content50DataSource.block2.title':'Global Fintech',
    'Content50DataSource.block3.title':'Space Exploration',
    'Content50DataSource.block4.title':'Crypto',
    'Content50DataSource.block5.title':'Metaverse & Gaming',
    'Content50DataSource.block6.title':'Biotech',
    'Feature30DataSource.title':'Investment as a Service',
    'Feature30DataSource.content':'We are committed to disseminating knowledge and insights, empowering our clients to make independent investment decisions. ',
    'Feature30DataSource.block1.title':'Consulting',
    'Feature30DataSource.block2.title':'Alternatives',
    'Feature30DataSource.block3.title':'Information Flow',
    'Feature30DataSource.block4.title':'Research',
    'Feature30DataSource.block5.title':'Ecosystem',
    'Feature30DataSource.block6.title':'Evergreen',
    'Feature30DataSource.block1.content':'Providing advisory services for disruptive start-ups and industry leaders over the next two decades ',
    'Feature30DataSource.block2.content':'Empowering entrepreneurs to make smart alternative investments',
    'Feature30DataSource.block3.content':'Promoting the circulation of insights and assets among start-ups of different stages',
    'Feature30DataSource.block4.content':'Conducting thorough research into sectors and companies set to deliver exponential growth',
    'Feature30DataSource.block5.content':'Building an open ecosystem where scientists and entrepreneurs engage in knowledge sharing',
    'Feature30DataSource.block6.content':'Launching flexible and long-lasting investment vehicles for the Next-Gen companies',
    'Banner10DataSource.title':'Investment Strategy',
    'Banner10DataSource.content':'We invest in top-performing, NextGen companies that will disrupt the next decade.',
    'Banner10DataSource.block1.title':'Category leader in a large, innovative and high growth market',
    'Banner10DataSource.block21.title':'Solid business model & considerable unit economics',
    'Banner10DataSource.block22.title':' ',
    'Banner10DataSource.block3.title':'Outstanding leadership team',
    'Feature00DataSource.P.title':'Strategic Capital Program',
    'Feature00DataSource.P.content':'Category leader in a large, innovative and high growth market',
    'Feature00DataSource.P.block1':'CASE FUNDS',
    'Feature00DataSource.P.block2':'FOF',
    'Feature00DataSource.P.block3':'THEMETIC FUNDS',
    'Feature00DataSource.P.block4':'CRYPTO',
    'Feature00DataSource.P.block5':'ACCOUNTS MANAGEMENT',
    'Teams40DataSource.title':'Team',
    'Teams40DataSource.content':'Young and fast growing founding team. Operating complicated deal transactions and best practices. ',
    'Teams30DataSource.title':'Follow unicorn observer Wechat public account',
    'Teams30DataSource.content':'Unicorn Observer is a leading investment knowledge-sharing platform in China with over 50,000 readers. We disseminate information and knowledge by delivering long-form essays on cutting-edge trends and disruptive businesses.',
    'Teams40DataSource.guangmi':'CEO',
    'Teams40DataSource.max':'Co-Founder & Head of Business Development',
    'Teams40DataSource.penny':'Co-Founder & Head of Research and Content',
    'Teams40DataSource.yilong':'Co-Founder & Head of Transaction and Compliance',
    'Teams40DataSource.jiahao':'Co-Founder & Head of Information Technology',
    'Teams40DataSource.yuanhong':'Co-Founder & Head of Crypto Labs',
    'Teams40DataSource.liaokun':'Vice President of Investments',
    'Teams40DataSource.siqi':'Vice President of Investment Research',
    'Teams40DataSource.rita':'Vice President of Business Development',
    'Teams40DataSource.patrick':'Strategic Initiatives Manager',
    'Teams40DataSource.jessie':'Analyst',
    'Teams40DataSource.kefei':'Analyst',
    'Teams40DataSource.chengjia':'Analyst',
    'Teams40DataSource.jingwen':'HR & Operations Manager',
    'Teams40DataSource.shitong':'Front-End Developer',
    'Teams40DataSource.haoyu':'Technical Engineer',
    'Teams40DataSource.binlu':'Head of Finance',
    'Teams40DataSource.hangbin':'Administration Manager',
}
export default Index