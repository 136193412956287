import TweenOne from 'rc-tween-one';
import { Menu } from 'antd';
import { getChildrenToRender } from './utils';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate,Link,useLocation  } from 'react-router-dom';
import { name } from 'pubsub-js';
import MenuComponent from '../Component/Menu'
const { Item, SubMenu } = Menu;



function App(props) {


  const { dataSource, isMobile } = props;
  const [phoneOpen, setPhoneOpen] = useState(undefined);
  const [iswhite, setiswhite] = useState(true);
  const [isActive, setIsActive] = useState('');
  const [screenIndex, setScreenIndex] = useState(0);
  const [menuList, setMenuList] = useState([{
    name: 'Research',
    url: 'research'
  },
  {
    name: 'Product',
    // url: 'agix'
    url: 'https://www.etnalabs.co/products'
  },
  {
    name: 'Contact Us',
    url: 'contact'
  }]);
  const his = useNavigate();
  const location = useLocation();
  useEffect(() => {
    window.addEventListener('scroll', () => {
     detectScroll()
       });
  },[])

  useEffect(() => {
    // 当路由变化时执行以下操作
    console.log('路由发生变化:', location.pathname);
    setIsActive(location.pathname.indexOf('contact'))
  if(location.pathname!=='/'){
    const div = document.querySelector('div[data-lastpass-root]');
    console.log(div);
    if(div){
      div.style.display = 'none'
    }
  }

    const canvas = document.getElementsByTagName('canvas');
    if(canvas.length > 0) {
      const dom = canvas[0]

      const flag = window.location.pathname === '/'||window.location.pathname === '/etna'||window.location.pathname === '/etna/'
      dom.style.display = flag?'block':'none'
    }
    setiswhite(true)
    setScreenIndex(0)
    // 在这里可以执行其他操作，例如隐藏Canvas
    // 例如：document.getElementById('your-canvas-id').style.display = 'none';
  }, [location.pathname]); // 监听location.pathname的变化


 const detectScroll=()=> {
    // 获取滚动容器元素
    
    // 获取滚动条的位置
    var scrollTop = window.scrollY;
    const windowHeight = window.innerHeight - 80;
    const pageHeight = document.documentElement.scrollHeight;
    // 获取所有带有 class="scrollable-div" 的 div 元素
    var divs = document.querySelectorAll(".page");
    const bottomThreshold = 20;
    const scrollPosition = window.scrollY ;
    const distanceToBottom = pageHeight - (scrollPosition + windowHeight);
    for (var i = 0; i < divs.length; i++) {
      var div = divs[i];
      var divTop = div.offsetTop;
      var divBottom = divTop + div.clientHeight;
      
      // 判断滚动条是否在当前 div 的范围内
      if (scrollTop >= divTop && scrollTop < divBottom) {
        const screenIndex =  i
        setiswhite(distanceToBottom <= bottomThreshold ? false: screenIndex % 2 ===0)
        setScreenIndex(screenIndex)
      }
    
    }
  }
  const go = (url) => {
    console.log(url);
    his.navigate(url);
  }

 const phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    setPhoneOpen(phoneOpen)
  };

  
  return location.pathname.indexOf('products')===-1?(
    <TweenOne
    component="header"
    animation={{ opacity: 0, type: 'from' }}
    {...dataSource.wrapper}
    {...props}
  
  >
    <div
      {...dataSource.page}
      className={`${dataSource.page.className}${phoneOpen ? ' open' : ''} ${dataSource.page.locationClassName}`}
      // style={{
      //   backgroundColor:location.pathname==='/'?'none':'#fff'
      //  }}
     
    >
      <TweenOne
        animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
        {...dataSource.logo}
      >
     <Link to="/" >
         {/* <div style={{backgroundImage:`url(../../public/home/${iswhite?'logo1':'logo3'}.png)`}} className="header3-logo-img"></div> */}
        <img  style={{cursor:'pointer',height:'100%'}} width="100%" src={`/home/logo2.png`} alt="img" />
        {/* <img  style={{cursor:'pointer'}} width="100%" src={`/home/${iswhite?'logo1':'logo3'}.png`} alt="img" /> */}
        </Link>
      </TweenOne>

            <MenuComponent menuList={menuList} />
    </div>
  </TweenOne>
  ):'';
}

export default App;
