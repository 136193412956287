/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useEffect, useRef, useState } from 'react'

import { enquireScreen } from 'enquire-js'
import { DataSource } from '../../data'
import Subscribe from '../../Component/Subscribe'
import { get, post } from '../../http'
import { Skeleton, message, Spin } from 'antd'
import { useParams, Link, useNavigate } from 'react-router-dom'
import FilterImg from '../FilterImg'
import TableComponent from '../Table'
import './index.less'
message.config({
    top: 100
})

let isMobile
enquireScreen((b) => {
    isMobile = b
})

function App(props) {




    useEffect(() => {

    }, [])

    useEffect(() => {
        
    }, [props])
    return (
        <div className="agixfooter-content">
            <div  className='agixfooter-left'>
               <div className='agixfooter-left-title'>
                     <div className='agixfooter-left-title-left'>AGIX Index</div>
                     <div className='agixfooter-left-title-right'>
                        <span>by</span>
                        &nbsp;
                        <img src={'/agix/logo.png'} alt="logo" />
                     </div>
               </div>
               <div className='agixfooter-left-desc'>Get Your Portfolio AGI-ready</div>
            </div>

            <div className='agixfooter-right'>
                <div className='agixfooter-right-tip'>
                    <div  className='agixfooter-right_tip'>Contact Us</div>
                    <img style={{cursor:"pointer"}} onClick={()=>{
                        window.open('https://twitter.com/EtnaResearch','_blank')
                    }} src={'/agix/x.png'} alt="logo" />
                    <img style={{cursor:"pointer"}} onClick={()=>{
                        window.open('https://www.linkedin.com/company/etna-fund','_blank')
                    }}  src={'/agix/linkin.png'} alt="logo" />
                </div>
                <div  className='agixfooter-right_tip'>Palo Alto · HongKong</div>
                </div>



        </div>

    )
}

export default App
