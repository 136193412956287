import React, { useEffect, useState } from 'react'

import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from './utils';
import { get, post } from '../http'
import DualAxesChart from '../Agix/Chat'

import { useNavigate, Link, useLocation } from 'react-router-dom';


function App(props) {
    const { dataSource, isMobile } = props;
    const queue = isMobile ? 'bottom' : 'left';
    const imgAnim = isMobile
        ? {
            y: 30,
            opacity: 0,
            delay: 600,
            type: 'from',
            ease: 'easeOutQuad',
        }
        : {
            x: 30,
            opacity: 0,
            type: 'from',
            ease: 'easeOutQuad',
        };





    return (<div {...props} {...dataSource.wrapper} style={{
        overflow: 'hidden',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "#C55A48"
    }}>
       
        <div
        className='banner-chat'
        style={{
            width:" 100%",
            maxWidth: "1437px"
        }}
        >
          <div 
          onClick={()=>{
            window.open('https://agixindex.etnalabs.co','_blank');
          }}
          style={{ cursor:"pointer", position: 'relative', zIndex: 999,textAlign:"left",marginBottom:"50px" }} className='connect-title'> Etna AGIX Index</div>
        </div>
        <div   style={{
            width:" 100%",
            maxWidth: "1437px"
        }}><DualAxesChart /></div>

    </div>)
}

export default App;
