/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useEffect, useRef, useState } from 'react'

import { enquireScreen } from 'enquire-js'
import { DataSource } from '../../data'
import Subscribe from '../../Component/Subscribe'
import { get, post } from '../../http'
import { Skeleton, message, Spin } from 'antd'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { DownloadIcon, RightAwoIcon } from '../icon'
import FilterImg from '../FilterImg'
import DownloadComponent from './download'
import moment from 'moment'
import TableComponent from '../Table'
import './index.less'
message.config({
    top: 100
})

let isMobile
enquireScreen((b) => {
    isMobile = b
})

function App(props) {


    const columns = [{
        title: "Number",
        dataIndex: "number"
    }, {
        title: "COMPANY",
        dataIndex: "company"
    }, {
        title: "TICKRT",
        dataIndex: "tickrt"
    }, {
        title: "Index Weight (%)",
        dataIndex: "indexWeight"
    }]


    const list = [{
        number: "1",
        company: "NVIDIA",
        tickrt: "NVDA UW Equity",
        indexWeight: "9.11%"
    }, {
        number: "2",
        company: "MICROSOFT",
        tickrt: "MSFT UW Equity",
        indexWeight: "8.33%"
    }, {
        number: "3",
        company: "APPLE",
        tickrt: "AAPL UW Equity",
        indexWeight: "5.54%"
    }, {
        number: "4",
        company: "AMAZON",
        tickrt: "AMZN UW Equity",
        indexWeight: "4.30%"
    }, {
        number: "5",
        company: "META",
        tickrt: "META UW Equity",
        indexWeight: "4.16%"
    }, {
        number: "6",
        company: "TAWAN SEMICONDUCTOR MANUFAC",
        tickrt: "2330TT Equity",
        indexWeight: "4.03%"
    }, {
        number: "7",
        company: "SK HYNIX",
        tickrt: "000660 KP Equity",
        indexWeight: "3.74%"
    }, {
        number: "8",
        company: "GOOGLE",
        tickrt: "GOOGL UW Equity",
        indexWeight: "3.32%"
    }, {
        number: "9",
        company: "ASML",
        tickrt: "ASML",
        indexWeight: "3.32%"
    }, {
        number: "10",
        company: "ARISTA NETWORKS",
        tickrt: "ANET UN Equity",
        indexWeight: "3.26%"
    }]



   

    useEffect(() => {

    }, [])

    useEffect(() => {
        
    }, [props])
    return (
        <div className="companies-content">
            <div className='companies-title'>Index Constituents</div>

            <div className='companies-tip'>
                <div className='companies-tip_text'>
                    <div className='companies-tip_text1'>As of {moment().format('ll')}</div>
                    <div className='companies-tip_text2'>For complete index information including methodology, visit SOLACTIVE.</div>
                </div>
                <DownloadComponent data={list} fileName="data_export" />

            </div>
            <div className='companies-table'>
                <TableComponent columns={columns} list={list} />
                <FilterImg />
            </div>

          
        </div>

    )
}

export default App
