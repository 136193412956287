import React, { useEffect, useRef, useState } from 'react'

import { enquireScreen } from 'enquire-js'
let isMobile
enquireScreen((b) => {
  isMobile = b
})
const App = ({ optList, onChange }) => {
    const [active, setActive] = useState('Explore')
    const clickHandler = useRef(false)
    const menuHighlight = useRef([])

    const handleScroll = (item) => {
        clickHandler.current = true
        const anchor = document.getElementById(item.name)
        const offset = item==="Explore"?576:322
        const anchorPosition =
            anchor.getBoundingClientRect().top +
            window.scrollY
        window.scroll({
            top: anchorPosition - offset,
            behavior: 'smooth' // 可选的平滑滚动
        })
        console.log('sss2');
        setActive(item.name)
        setTimeout(() => {
            clickHandler.current = false

        }, 2000)
    }
    const listenerScroll = () => {
        console.log(clickHandler.current);
        if (clickHandler.current) return
        const first = menuHighlight.current[0]
        const scrollY = window.scrollY>first?window.scrollY+526:window.scrollY+322
        const it = menuHighlight.current.findIndex((i, j) => {
          const nx = menuHighlight.current[j + 1]
          return nx ? scrollY >= i && scrollY < nx : scrollY >= i
        })
    
        if (it === -1) {
          setActive(optList[0].name)
        } else {
          setActive(optList[it].name)
        }
      };
      const checkMenuHighlight = (i) => {
        const anchor = document.getElementById(i.name)
        if (anchor) {
          const offset = i.name==="Explore"?526:272 // 设置偏移量，以像素为单位
          const anchorPosition = anchor.getBoundingClientRect().top + window.scrollY
          return anchorPosition - offset
        }
        return 0
      }
      const setMenuHeightFun = () => {
        const arr = optList.map((i, j) => {
          const it = checkMenuHighlight(i)
          return it
        })
        onChange(arr[0])
        menuHighlight.current = arr
        console.log(arr);
      }

    useEffect(() => {
        console.log('sadsa');
        window.scroll({
          top: 0,
          behavior: 'smooth' // 可选的平滑滚动
        })
        const agixTopLeft = document.querySelector('.agix-body_left')
        const agixTopLeftContent = document.querySelector('.agix-body_left_content')
        const rect = agixTopLeft.getBoundingClientRect();
        agixTopLeftContent.style.left = `${rect.left}px`;
        setTimeout(() => {
          // 添加滚动事件监听器
          setMenuHeightFun()
          window.addEventListener('scroll', listenerScroll)
        }, 1000)
    
        // 在组件卸载时移除事件监听器
        return () => {
          window.removeEventListener('scroll', listenerScroll);
        };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);



    return (<div className="agix-body_left" >
        <div className="agix-body_left_content" style={{ top: isMobile? 0:"576px" }}>
            {optList.map((item, index) => (<div key={index}
                onClick={() => handleScroll(item)}
                className={`agix-opt-menu ${active === item.name ? "agix-opt-active" : ""}`}>
                <div>  {item.name} </div>
            </div>))}
        </div>
    </div>)
}

export default App