import axios from 'axios'

export function get(url, headers){ 
    return new Promise((resolve, reject) =>{ 
       axios.get(url, {
          headers
       }).then(response => {
          resolve(response.data);
       }).catch(error =>{
          reject(error) 
       }) 
    });
  }

  export function post(url, data,headers){ 
    return new Promise((resolve, reject) =>{ 
       axios.post(url, data, {
        headers
       }).then(response => {
          resolve(response.data);
       }).catch(error =>{
          reject(error) 
       }) 
    });
  }