/**
 * @description blockquote menu class
 * @author wangfupeng
 */

import { DomEditor, IDomEditor, SlateEditor, SlateNode, SlateTransforms } from '@wangeditor/editor'

class PrefaceMenu  {
   title = '引言'
   tag = 'button'

  getValue(editor) {
    // 用不到 getValue
    return ''
  }

  isActive(editor) {
    const node = DomEditor.getSelectedNodeByType(editor, 'preface')
    return !!node
  }

  isDisabled(editor) {
    if (editor.selection == null) return true

    const [nodeEntry] = SlateEditor.nodes(editor, {
      match: n => {
        const type = DomEditor.getNodeType(n)

        // 只可用于 p 和 blockquote
        if (type === 'paragraph') return true
        if (type === 'preface') return true

        return false
      },
      universal: true,
      mode: 'highest', // 匹配最高层级
    })

    // 匹配到 p blockquote ，不禁用
    if (nodeEntry) {
      return false
    }
    // 未匹配到，则禁用
    return true
  }

  /**
   * 执行命令
   * @param editor editor
   * @param value node.type
   */
  exec(editor, value) {
    if (this.isDisabled(editor)) return

    const active = this.isActive(editor)
    const newType = active ? 'paragraph' : 'preface'

    // 执行命令
    SlateTransforms.setNodes(editor, { type: newType }, { mode: 'highest' })
  }
}

export default PrefaceMenu
