import dayjs from 'dayjs';
import moment from 'moment-timezone';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const holidays = [
  "01-01", // New Year's Day
  "01-17", // Martin Luther King Jr. Day
  "02-21", // Presidents' Day
  "04-15", // Good Friday
  "05-30", // Memorial Day
  "07-04", // Independence Day
  "09-05", // Labor Day
  "11-24", // Thanksgiving Day
  "12-25", // Christmas Day
];

// 获取某年某月的第n个指定工作日
function nthWeekdayOfMonth(year, month, weekday, nth) {
  const firstDay = new Date(year, month, 1).getDay();
  const day = 1 + ((weekday - firstDay + 7) % 7) + (nth - 1) * 7;
  return new Date(year, month, day);
}

// 获取某年某月最后一个指定工作日
function lastWeekdayOfMonth(year, month, weekday) {
  const lastDay = new Date(year, month + 1, 0).getDay();
  const day =
    new Date(year, month + 1, 0).getDate() - ((lastDay - weekday + 7) % 7);
  return new Date(year, month, day);
}

function isUSHoliday(_date) {
  const date = new Date(_date);
  const year = date.getFullYear();

  // 固定节假日
  const fixedHolidays = [
    { month: 0, day: 1 },  // New Year's Day (January 1)
    { month: 5, day: 19 }, // Juneteenth (June 19)
    { month: 6, day: 4 },  // Independence Day (July 4)
    { month: 10, day: 11 },// Veterans Day (November 11)
    { month: 11, day: 25 } // Christmas Day (December 25)
  ];

  // 浮动节假日
  const floatingHolidays = [
    nthWeekdayOfMonth(year, 0, 1, 3),  // Martin Luther King Jr. Day (3rd Monday of January)
    nthWeekdayOfMonth(year, 1, 1, 3),  // Presidents' Day (3rd Monday of February)
    lastWeekdayOfMonth(year, 4, 1),    // Memorial Day (last Monday of May)
    nthWeekdayOfMonth(year, 8, 1, 1),  // Labor Day (1st Monday of September)
    nthWeekdayOfMonth(year, 10, 4, 4)  // Thanksgiving Day (4th Thursday of November)
  ];

  // 检查固定节假日
  for (const holiday of fixedHolidays) {
    if (date.getMonth() === holiday.month && date.getDate() === holiday.day) {
      return true;
    }
  }

  // 检查浮动节假日
  for (const holiday of floatingHolidays) {
    if (
      date.getMonth() === holiday.getMonth() &&
      date.getDate() === holiday.getDate()
    ) {
      return true;
    }
  }

  return false;
}
// 检查某一天是否为交易日
function isTradingDay(date) {
  const day = date.day(); // dayjs 的 day() 方法返回 0 (Sunday) 到 6 (Saturday)

  // 检查是否是周末
  if (day === 0 || day === 6) {
    return false; // 是周日或周六
  }

  // 检查是否是节假日
  const dateFormatted = date.format('YYYY-MM-DD');

  if (isUSHoliday(dateFormatted)) {
    return false; // 是节假日
  }

  return true; // 是交易日
}

function getTradeDay(lastDay) {
  const _now = lastDay?lastDay:moment().tz('America/New_York').format('YYYY-MM-DD');

  const _nowTimezone = moment(_now);
  const _nowTradingDay = isTradingDay(_nowTimezone) ? _now : getLastTradingDay(_nowTimezone);
  const _lastDayStart = moment(_nowTradingDay).subtract(1, 'day').format('YYYY-MM-DD')
  const _lastWeekStart = moment(_nowTradingDay).subtract(1, 'week').format('YYYY-MM-DD')
  const yearStart = moment(moment().format('YYYY-01-01 12:00:00'))
  const firstTradingDay =  getLastTradingDay(yearStart,1);
  const _YTD = firstTradingDay.format('YYYY-MM-DD');
  
  return {
    now:moment().tz('America/New_York').format('YYYY-MM-DD'),
    nowTradingDay: _nowTradingDay,
    lastDay: _lastDayStart,
    lastWeek: _lastWeekStart,
    YTD: _YTD
  }

}

function getLastTradingDay(date, days = 1) {
  let lastDay = date.subtract(days, 'day'); // 减去一天
  while (!isTradingDay(lastDay)) {
    lastDay = lastDay.subtract(days, 'day'); // 如果不是交易日，继续往前找
  }
  return lastDay;
}


export {
  getLastTradingDay,
  getTradeDay,
  isTradingDay
}