import React from 'react';



export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper ' },
  page: { className: 'home-page', locationClassName:'nav3' },
  logo: {
    className: 'header3-logo',
    children:
      `/home/logo1.png`,
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'menu-btn',
        children: {
          href: '#',
          children: [{ children: 'Research', name: 'text' }],
        }
      },
      {
        name: 'item1',
        className: 'menu-btn',
        children: {
          href: '#',
          children: [{ children: 'Contact Us', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3 lmef978jj3-editor_css page' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '< fueling the future, navigator for innovators',
        texty: true,
      },
    ],
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper page' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 12 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p id="text" className='content6-child-text'>
              Fueled by global insights, we exclusively invest in disruptive innovators and industries that are shaping the next decade.
              </p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  img: {
     children: [],
    className: 'content6-img',
    xs: 24,
    md: 8,
  },
  block: {
    children: [
      // {
      //   name: 'block0',
      //   title: {
      //     className: 'content6-title',
      //     children: '',
      //   },
      //   content: {
      //     className: 'content6-content',
      //     children: (
      //       <span>
      //         <p>
      //           <br />
      //         </p>
      //         <p>AI-native · Enterprise · Fintech · Gaming · Frontier-Tech…</p>
      //       </span>
      //     ),
      //   },
      // },
    ],
  },
};

export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper  research-wrapper  page' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 14 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [],
  },
  img: {
     children: [],
    className: 'content6-img',
    xs: 24,
    md: 10,
  },
  block: {
    children: [
      {
        name: 'block0',
        title: {
          className: 'content6-title',
          children: '',
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                <br />
              </p>
              <p>AI-native · Enterprise · Fintech · Gaming · Frontier-Tech…</p>
            </span>
          ),
        },
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper lmefhvwvkro-editor_css page',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '', className: 'title-h1' },
    ],
  },
  button: {
    className: '',
    children: { a: { className: 'button', href: '#', children: '立即报名' } },
  },
};
export const Feature41DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper page conte5' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>ETNA Research</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/VHGOVdYyBwuyqCx.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: { className: 'content6-title', children: '技术' },
        content: {
          className: 'content6-content',
          children:
            '丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。',
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: { className: 'content6-title', children: '融合' },
        content: {
          className: 'content6-content',
          children:
            '解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: { className: 'content6-title', children: '开发' },
        content: {
          className: 'content6-content',
          children:
            '符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。',
        },
      },
    ],
  },
};


export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper page' },
  page: { className: 'home-page content0 keyword-wrapper' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper keyword-wrapper-child',
    children1: [
      {
        name: 'block0',
        className: 'content0-block keyword-content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
          
            {
              name: 'title',
              className: 'keyword-item',
              children: 'Insight-led',
            },
            { name: 'content', children: '' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block keyword-content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'title',
              className: 'keyword-item',
              children: 'Innovation-focused',
            },
            {
              name: 'content',
              children: '',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block keyword-content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'title',
              className: 'keyword-item',
              children: 'Ecosystem',
            },
            {
              name: 'content',
              children: '',
            },
          ],
        },
      },
    ],
    children2: [
      {
        name: 'block0',
        className: 'content0-block keyword-content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
          
            {
              name: 'title',
              children: '',
            },
            { name: 'content', 
            className: 'keyword-item-desc',
            children: 'By leveraging diverse analysis tools, we build a unique perspective that can keep us ahead of the curve and seek out big ideas.' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block keyword-content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'title',
              children: '',
            },
            {
              name: 'content',
              className: 'keyword-item-desc',
              children: 'As investors, we are drawn to pioneering souls, and relentlessly back the 1% who’re defining the future through technology and disruptive innovations.',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block keyword-content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'title',
              children: '',
            },
            {
              name: 'content',
              className: 'keyword-item-desc',
              children: `Many of our LPs are tech companies' founders, CEOs, or CTOs. We are building an ecosystem between industry and academia, connecting innovators worldwide.`,
            },
          ],
        },
      },
    ],
  },
};
export const Teams00DataSource = {
  wrapper: { className: 'home-page-wrapper teams0-wrapper connect page' },
  OverPack: { playScale: 0.3, className: 'home-page teams0' },
  BannerAnim: {
    className: 'banner-anim',
    children: [],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '' },
        childWrapper: {
          children: [],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '' },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '#',
              children: (
                <span>
                  <span>
                    <p className='link'>Twitter</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '#',
              children: (
                <span>
                  <p className='link'>Linkedin</p>
                </span>
              ),
            },
            {
              name: 'link2',
              href: '#',
              children: (
                <span>
                  <p className='link'>Contact</p>
                </span>
              ),
            },   {
              name: 'link2',
              href: '#',
              children: (
                <span>
                  <p className='link'>Palo Alto · New York · Singapore · HongKong</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '' },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <span>
                    <p className='link'>Privacy Policy</p>
                  </span>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link1',
              children: (
                <span>
                  <p className='link'>Contact Us</p>
                </span>
              ),
            },{
              href: '#',
              name: 'link1',
              children: (
                <span>
                  <p className='link'>Subscribe</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          ©2023 by <a href="https://motion.ant.design">E</a>tna All Rights
          Reserved
        </span>
      </span>
    ),
  },
};
