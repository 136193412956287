/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useEffect, useRef, useState } from 'react'

import { enquireScreen } from 'enquire-js'
import { DataSource } from '../../data'
import Subscribe from '../../Component/Subscribe'
import { get, post } from '../../http'
import { Skeleton, message, Spin } from 'antd'
import { CpuIcon, CloudConnectionIcon, MoniutorIcon } from '../icon'
import { useParams, Link, useNavigate } from 'react-router-dom'
import Ring from '../Chat/Ring'
import './index.less'
message.config({
    top: 100
})

let isMobile
enquireScreen((b) => {
    isMobile = b
})

function App(props) {
    const [isHover, setIsHover] = useState('')
    const displayNone = useRef({})

    const blockList = [{
        name: 'Al Hardware',
        desc:"Semiconductors and Equipment",
        icon: <CpuIcon color={isHover==='a'?"#5E3290":"#333333"}/>,
        className:"a",
        logos:"/agix/AIHardware.png",
        percent:0.4,
        color:['#826ac81a', '#5E3290',"#826AC8"]
    },{
        name: 'Al Infra',
        desc:"Cloud and Models",
        icon: <CloudConnectionIcon color={isHover==='b'?"#96B05A":"#333333"}/>,
        className:"b",
        logos:"/agix/Infrastructure.png",
        percent:0.4,
        color:['#96b05a1a', '#96B05A',"#96B05A"]

    },{
        name: 'Application',
        desc:"Adaptive Software",
        icon: <MoniutorIcon color={isHover==='c'?"#ED8F4B":"#333333"}/>,
        className:"c",
        logos:"/agix/Application.png",
        percent:0.2,
        color:['#ed8f4b1a', '#ED8F4B',"#ED8F4B"]
    }]

    const quadrantList = [[
        {
            name:"Adopters",
            url: "1.png",
            className:"left-top",
            desc:"Companies make significant strategic investments in Al, or have already launched Al-driven products or business lines that are expected to bring new revenue growth soon. "
        },{
            name:"Leaders",
            url: "2.png",
            className:"right-top",
            desc:"Companies make significant strategic investments in Al, or have already launched Al-driven products or business lines that are expected to bring new revenue growth soon. "
        }
    ],[
        {
            name:"Beginners",
            url: "3.png",
            className:"left-bottom",
            desc:"Their attempts with Al are more elementary, often limited to the feature level."
        },{
            name:"Enablers",
            url: "4.png",
            className:"right-bottom",
            desc:"Companies make significant strategic investments in Al, or have already launched Al-driven products or business lines that are expected to bring new revenue growth soon. "
        }
    ]]

    useEffect(() => {
        const items = document.querySelectorAll('.philosophy-block_item');
        console.log(items);
        
       if(!isMobile){
        items.forEach((item, index) => {
            item.addEventListener('mouseenter', () => {
              setIsHover(item.id)
              const object = {}
              items.forEach((otherItem, otherIndex) => {
                if (otherIndex === index) {
                  otherItem.style.width = '640px';
                  object[otherItem.id] = 1
                } else {
                  otherItem.style.width = '220px';
                  object[otherItem.id] = 0
                }
              });
              displayNone.current = object
            });
      
            item.addEventListener('mouseleave', () => {
              setIsHover("")
              const object = {}
              items.forEach(otherItem => {
                otherItem.style.width = '360px';
                object[otherItem.id] = 1
              });
              displayNone.current = object
  
             
            });
          });
       }
    }, [])

    useEffect(() => {
        
    }, [props])
    return (
        <div className="philosophy-content">
            <div className='philosophy-title'>AGIX Index Construction</div>
            <div className='philosophy-block'>
                {blockList.map((item, index) => (<div key={index} id={`${item.className}`} className={`philosophy-block_item ${item.className}`}>
                    <div  className='philosophy-block_icon'>{item.icon}</div>
                    <div className='philosophy-block_name'>{item.name}</div>
                    <div className='philosophy-block_desc' style={{display:displayNone.current[item.className] === 0?"none":"block"}}>
                        <div>{item.desc}</div>
                    </div>
                    <div className='philosophy-block_bg'></div>
                    <div  className='philosophy-block_logos'> 
                        <div className='philosophy-block_logos_block'>
                        <img src={`${item.logos}`} alt=""/>
                        <Ring isMobile={isMobile} percent={item.percent} color={item.color} />
                        </div>
                    <div className='philosophy-block_tip'>...and more</div>
                    </div>
     
                </div>))}
               
             

            </div>
            <div className='philosophy-tip'>
                <div className='philosophy-tip1'>AGIX Potential Matrix</div>
                <div className='philosophy-tip2'>
                To evaluate companies for inclusion in the index, we established criteria to determine whether a company can be included. These criteria are visualized in the AGIX Potential Matrix, where the horizontal axis represents AI Readiness and the vertical axis represents AI Potential.
                </div>
            </div>
            <div className='philosophy-quadrant'>
                <div className='philosophy-quadrant_line1_text'>AI Potential</div>
                <div className='philosophy-quadrant_line1'></div>
                <div className='philosophy-quadrant_line2'></div>
                <div className='philosophy-quadrant_line2_text'>AI Readiness</div>
                {quadrantList.map((item, index) => {
                    return <div key={index} className='philosophy-quadrant_row'>
                        {item.map((item1, index1) => {
                            return <div key={index1} className={`philosophy-quadrant_item`}>
                            <div style={{textAlign: 'center'}}><img src={`/agix/${item1.url}`} alt=""/></div>
                            <div className={`philosophy-quadrant_name  ${item1.className}`}>{item1.name}</div>
                            <div className={`philosophy-quadrant_itembgdesc block_${index1}`}>{item1.desc}</div>
                        </div>
                        })}
                    </div>
                })}
            </div>

            <div  className='philosophy-end'>
                <div className='philosophy-end_row'>
                    <div  className='philosophy-end_row_title'>Al potential</div>
                    <div  className='philosophy-end_row_desc'>assesses how integrated Al is within a company's current operations. The more Al positively impacts the business and generates revenue, the higher the Al Readiness score.</div>
                </div>

                <div  className='philosophy-end_row'>
                    <div  className='philosophy-end_row_title'>Al Readiness</div>
                    <div className='philosophy-end_row_desc'>
                    is assessing the future impact and revenue potential of integrating Al within a company over the next few years. As Al models become more advanced, they will unlock new scenarios currently limited by existing technology, providing companies with significant Al exposure. Identifying these "value troughs" ahead of the market is crucial for capturing alpha returns.
                    </div>
                </div>
            </div>


           
        </div>

    )
}

export default App
