/**
 * @description numbered list menu
 * @author wangfupeng
 */

import BaseMenu from './BaseMenu'
import { NUMBERED_LIST_SVG } from '../svg'

class NumberedListMenu extends BaseMenu {
   ordered = true
   title = '有序列表'
   iconSvg = NUMBERED_LIST_SVG
}

export default NumberedListMenu
