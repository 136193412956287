/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Banner0 from './Banner0';
import Banner1 from './Banner1';

import { Banner00DataSource, Banner10DataSource } from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    return (
      <div
      style={{maxHeight:'100vh'}}
        className="templates-wrapper content"
        ref={(d) => {
          this.dom = d;
        }}
      >
        <div className="c-title">
        Get in Touch
        </div>
        <div className="c-tip">
        Etna Capital Management is established by AI-native investors and engineers. 
        We focusing on artificial intelligence and leveraging deep technical expertise and extensive network to identify and support groundbreaking AI companies.
        </div>
        <div className="c-email">hi@etnalabs.co</div>
        {/* <div className="c-icon">
          <div className="c-icon-item ">
            <a href="https://twitter.com/EtnaResearch" target="_blank" ><div className="x"></div></a>
           
          </div>
          <div className="c-icon-item">
          <a href="https://www.linkedin.com/company/etna-fund" target="_blank"><div className="in"></div></a>

          </div>
        </div>
        <div className='c-desc'>Palo Alto  · HongKong</div> */}

      </div>
    );
  }
}
