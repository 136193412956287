/**
 * @description to html
 * @author wangfupeng
 */


function quoteToHtml(elem, childrenHtml) {
  return `<remark>${childrenHtml}</remark>`
}

export const quoteToHtmlConf = {
  type: 'remark',
  elemToHtml: quoteToHtml,
}
