import React from 'react';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from './utils';

class Content7 extends React.Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // 创建一个ref来引用DOM元素
    this.state = {
      width: 1000,
      top:0,
      screenIndex:0
    }
  }
   detectScroll() {
    // 获取滚动容器元素
    
    // 获取滚动条的位置
    var scrollTop = window.scrollY;
    
    // 获取所有带有 class="scrollable-div" 的 div 元素
    var divs = document.querySelectorAll(".page");
    const fixedButton = document.querySelector('.title-wrapper');
    const rect = fixedButton.getBoundingClientRect()
    for (var i = 0; i < divs.length; i++) {
      var div = divs[i];
      var divTop = div.offsetTop;
      var divBottom = divTop + div.clientHeight;
      
      // 判断滚动条是否在当前 div 的范围内
      if (scrollTop >= divTop && scrollTop < divBottom) {
        const screenIndex =  i
        if(screenIndex === 1 && !fixedButton.style.top){
          const top = rect.top - rect.height
          fixedButton.style.position = "sticky";
          fixedButton.style.top = `${rect.top}px`;
          this.setState({
            top:top,
          })
        }
        this.setState({
          screenIndex
        })
      }
    
    }
  }
  componentDidMount() {
        window.addEventListener('scroll', () => {
          this.detectScroll()
        });
  }

  getDom = () => {
    const element = this.myRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      this.setState({ width: rect.left + rect.width})
    }
  }


  getBlockChildren = (data) =>
    data.map(($item) => {
      const { ...item } = $item;
      const { title, img, content } = item;
      ['title', 'img', 'content'].forEach((key) => delete item[key]);
      return (
        <div key={item.name} {...item}>
          {img && <span {...img}>
            <img src={img.children} width="100%" alt="img" />
          </span>}
          <h2 {...title}>{title.children}</h2>
          <div {...content}>{content.children}</div>
        </div>
      );
    });

  render() {
    const { ...props } = this.props;
    const { width,screenIndex } = this.state
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    const ulChildren = this.getBlockChildren(dataSource.block.children);
    const queue = isMobile ? 'bottom' : 'left';
    const imgAnim = isMobile
      ? {
        y: 30,
        opacity: 0,
        delay: 600,
        type: 'from',
        ease: 'easeOutQuad',
      }
      : {
        x: 30,
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      };
    const list = [
      {
        title: '$1B+',
        desc: 'Over $1B AUM'
      }, {
        title: '20+',
        desc: 'Unicorn portfolios'
      }, {
        title: '100+',
        desc: 'Top Tech Entrepreneurs in our Ecosystem'
      }, {
        title: '200+',
        desc: 'Scientists and Tech Professionals Connection'
      }, 
      {
        title: '100+',
        desc: 'Deep Research on Private Tech Companies'
      }
    ];

    const getIdx = (idx) => {
      let cla = ''
      switch (idx) {
        // case 0: case 4:
        //   cla='small'
        //   break;
        //   case 1: case 3:
        //     cla='mid'
        //     break;
            default:
              cla='large'
              break;
      }
      return `${cla}`
    }

    return (
      <div {...props} {...dataSource.wrapper}>
        <div style={{position:screenIndex!==1?'absolute':'fixed'}} className="bg"></div>
        <OverPack {...dataSource.OverPack} component={Row}>
          <QueueAnim
            key="text"
            type={queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.textWrapper}
            component={Col}
            onEnd={() =>{
              
              this.getDom()
            }}
          >
            <div key="title" {...dataSource.titleWrapper}>
              <div ref={this.myRef} className="point-block">
              <div className="point"></div>
              <div style={{width:`1000px`}} className="point-line"></div>
              </div>
              {dataSource.titleWrapper.children.map(getChildrenToRender)}
            </div>
            <QueueAnim
              component="ul"
              key="ul"
              type={queue}
              ease="easeOutQuad"
              {...dataSource.block}
            >
              {ulChildren}
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            {...dataSource.img}
            component={Col}
          >
            <div className="list-block">
              <div className="list-block-mask"></div>
              {list.map((i, j) => {

                return <div  className={`list-item ${getIdx(j)} `} key={j}>
                  <div  className={`list-title`}>{i.title}</div>
                  <div  className="list-desc">{i.desc}</div>
                </div>
              })}

            </div>


          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Content7;
