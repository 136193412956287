/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useEffect, useRef, useState } from 'react'
import './index.less'

function App(props) {

    return (
        <div className="filter-content">
          <div  className="filter-content_bg1">
            <img src={"/agix/Ellipse17.png"} alt=""/>
               
            </div>
            <div  className="filter-content_bg2">
                <img src={"/agix/Ellipse16.png"} alt=""/>
            </div>
        </div>

    )
}

export default App
