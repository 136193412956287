/**
 * @description bulleted list menu
 * @author wangfupeng
 */

import BaseMenu from './BaseMenu'
import { BULLETED_LIST_SVG } from '../svg'
import { DomEditor, IDomEditor, SlateEditor, SlateNode, SlateTransforms } from '@wangeditor/editor'

class BulletedListMenu extends BaseMenu {
   ordered = false
   title = '无序列表'
   iconSvg = BULLETED_LIST_SVG
}

export default BulletedListMenu
