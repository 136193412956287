/* eslint-disable react-hooks/exhaustive-deps */
import { DualAxes } from '@ant-design/plots';
import { useEffect, useRef, useState } from 'react';
import FilterImg from '../FilterImg'
import { get, post } from '../../http'
import { ChatData } from './data';
import DualAxesChartComponent from './chat'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import './index.less'
import dayjs from 'dayjs';
import { Spin } from 'antd';
import { getTradeDay, isTradingDay } from '../../utils'
import moment from 'moment';

dayjs.extend(utc);
dayjs.extend(timezone);
const DualAxesChart = ({ isShowIcon, onChange, isMobile }) => {
    const [active, setActive] = useState('ALL');
    const [dataSource, setDataSource] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const lastSolativeDate = useRef('')
    const now = dayjs().tz('America/New_York');
    const lastDay = useRef(now.subtract(1, 'day').format('YYYY-MM-DD'))
    const lastWeek = useRef(now.subtract(1, 'week').format('YYYY-MM-DD'))
    const YTD = useRef(now.startOf('year').format('YYYY-MM-DD'))
    const today = useRef(now.format('YYYY-MM-DD'))

    const dateTag = ["ALL", "1W", "1M", "3M", "6M", "1Y", "3Y"];

    const dateValue = {
        "ALL": { index: 5, type: 'year' },
        "1W": { index: 1, type: 'week' },
        "1M": { index: 1, type: 'month' },
        "3M": { index: 3, type: 'month' },
        "6M": { index: 6, type: 'month' },
        "1Y": { index: 1, type: 'year' },
        "3Y": { index: 3, type: 'year' },
    }


    const setDateSourceByTag = (_lastDay) => {
        const data = getTradeDay(_lastDay)
        today.current = data.nowTradingDay // 今天
        lastDay.current = data.lastDay // 今天之前最后一个交易日
        lastWeek.current = data.lastWeek
        YTD.current = data.YTD
        console.log(data);

    }

    useEffect(() => {


    }, [])



    useEffect(() => {
        const element = document.getElementById("chat");
        const rect = element.getBoundingClientRect()
        element.style.height = rect.width / 2.77 + "px"
    }, [])


    async function getBasicTradeData({ start_date, end_date }) {
        try {
            let url1 = `https://test.daomengbiji.com/api/basic?start_date=${start_date}&end_date=${end_date}`
            const result = await get(url1, {})
            return result
        } catch (e) {

            return []
        }
    }

    async function getSolactiveTradeData({ start_date, end_date }) {
        try {
            let url1 = `https://www.shixiangcap.com/inception/get-solactive`
            const result = await post(url1, {
                start_date: `${start_date} 06:59:59`,
                end_date: `${end_date} 07:00:00`,
            })
            const solactiveData = result.data;
            const initialBase = 100;
            const solactiveBase = [initialBase];
            for (let i = 1; i < solactiveData.length; i++) {
                const previousValue = parseFloat(solactiveData[i - 1].value);
                const currentValue = parseFloat(solactiveData[i].value);
                const newBase = (solactiveBase[i - 1] * currentValue) / previousValue;
                solactiveBase.push(newBase);
            }
            const solactive = solactiveData.map((entry, index) => {
                const v = solactiveBase[index] ? Number(solactiveBase[index].toFixed(2)) : 0
                const s = entry.value ? Number(parseFloat(entry.value).toFixed(2)) : 0;
                return {
                    type: `SOLEAGIX`,
                    value: v, // 格式化基数
                    time: entry.timestamp, // 格式化日期
                    source: s, // 格式化值
                    date: moment(entry.timestamp).format('YYYY-MM-DD')
                }
            }).map((entry, index) => {
                const day = moment(entry.time).day(); // dayjs 的 day() 方法返回 0 (Sunday) 到 6 (Saturday)
                let d = entry.date
                // 检查是否是周末
                if (day === 0) {
                    d = moment(entry.time).subtract(2, 'day');
                }
                return {
                    ...entry,
                    tradedate: moment(d).format('YYYY-MM-DD'),
                    date1: entry.date,
                    isTradingDay: isTradingDay(moment(d)),
                }
            }).filter((entry, index) => {
                const isBetween = moment(entry.tradedate).isBetween(start_date, end_date, null, '[]');
                return isBetween && entry.isTradingDay
            })
            if(solactive.length>0){
                const last = solactive[solactive.length - 1]
                lastSolativeDate.current = last.tradedate
                setDateSourceByTag(lastSolativeDate.current)
            }
            return solactive
        } catch (e) {
            console.log(e.message);

            return []
        }
    }

    async function fetchData({ index, type }) {
        try {
            const end = now.format('YYYY-MM-DD');
            const start = now.subtract(index, type).format('YYYY-MM-DD');
            setIsLoading(true)
            const solactive = await getBasicTradeData({ start_date: start, end_date: end })
            const basic = await getSolactiveTradeData({ start_date: start, end_date: end })
            const result = [...basic, ...solactive]

            

            setIsLoading(false)
            const response = result
            setDataSource(response)

            if (active === "ALL") {
                const nowData = response.filter(item => item.tradedate === today.current)
                const lastDayData = response.filter(item => item.tradedate === lastDay.current)
                const lastWeekData = response.filter(item => item.tradedate === lastWeek.current)
                const YTDData = response.filter(item => item.tradedate === YTD.current)
                if (onChange) onChange({ lastDayData, lastWeekData, YTDData, nowData, 
                    date:{
                        today:today.current,
                        lastDay:lastDay.current,
                        lastWeek:lastWeek.current,
                        YTD:YTD.current
                    }
                 })
            }
            return response
        } catch (e) {
            setIsLoading(false)

            return {
                success: false,
                message: e.message
            }
        }
    }



    useEffect(() => {
        // const arr = filterDataByTag(active)
        const source = dateValue[active]
        fetchData(source)
        // setDataSource(arr)
    }, [active])

    return <Spin spinning={isLoading}><div className='chat' id="chat">
        <div className='chat-block'>
            {dateTag.map((item, index) => (<div onClick={(e) => {
                setActive(item)
            }} key={index} className={`chat-block-tag ${active === item ? "chat-block-tag_active" : ""}`}>{item}</div>))}
        </div>
        <div style={{ width: '100%', height: '100%' }}>
            <DualAxesChartComponent isLoading={isLoading} dataSource={dataSource} isMobile={isMobile} />
        </div>

        {isShowIcon && <FilterImg />}

    </div></Spin>

};

export default DualAxesChart;