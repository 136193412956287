import React from 'react'
import { Button } from 'antd'
import QueueAnim from 'rc-queue-anim'
import Texty from 'rc-texty'
import 'rc-texty/assets/index.css'

class Banner extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isHovered: false,
    }
  }
  handleMouseEnter = () => {
    this.setState({ isHovered: true })
  }

  handleMouseLeave = () => {
    
    this.setState({ isHovered: false })
  }



  render() {
    const { isHovered } = this.state

    const { ...currentProps } = this.props
    const { dataSource } = currentProps
    delete currentProps.dataSource
    delete currentProps.isMobile
    const children = dataSource.textWrapper.children.map((item) => {
      
      const { name, texty, ...$item } = item
      if (name.match('button')) {
        return (
          <Button type="primary" key={name} {...$item}>
            {item.children}
          </Button>
        )
      }

      console.log();
    const  handleShow = () => {
        // 文本动画结束时触发
        console.log('动画结束');
        // 在这里可以执行你想要的操作
      }
      return (
        <div key={name} {...$item}>
          {texty ? (
            <div className='point' style={{display:'flex',alignItems: 'center'}}>
              <Texty  type="mask-bottom">{item.children}</Texty>
              <span className='mast-point'></span>
              <Texty delay={2300} type="mask-bottom"> &nbsp;&gt; </Texty>
            </div>
          ) : (
            item.children
          )}
        </div>
      )
    })
    return (
      <div {...currentProps} {...dataSource.wrapper}>
     

<div className={`banner3-block ${isHovered?'banner3-fadeout':'banner3-fadein'}`}>
            <div
              onMouseEnter={this.handleMouseEnter}
              className="banner3-item"
            ></div>
            <div className="banner3-mask"></div>
          </div>

        {isHovered && (
          <div style={{cursor:'pointer'}}
          // onMouseLeave={this.handleMouseLeave}
          >
            <QueueAnim
              key="QueueAnim"
              type={['bottom', 'top']}
              delay={200}
              {...dataSource.textWrapper}
              onMouseLeave={this.handleMouseLeave}
            >
              {children}
            </QueueAnim>
          </div>
        )}
      </div>
    )
  }
}
export default Banner
