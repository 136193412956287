/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import { enquireScreen } from 'enquire-js'

import Teams4 from './Teams4'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // 导入Quill的样式文件
import { Content130DataSource, Teams40DataSource } from './data.source'
import './less/antMotionStyle.less'

let isMobile
enquireScreen((b) => {
  isMobile = b
})

const { location = {} } = typeof window !== 'undefined' ? window : {}

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      text: '',
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b })
    })
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        })
      }, 500)
    }
    /* 如果不是 dva 2.0 请删除 end */
  }
  handleChange = (value) => {
    this.setState({ text: value })
  }
  copyToClipboard = () => {
    const downloadLink = document.getElementById('downloadLink')
    const blob = new Blob([this.state.text], { type: 'text/plain' })
    downloadLink.href = URL.createObjectURL(blob)

    // 显示下载链接并触发下载
    downloadLink.style.display = 'block'
    downloadLink.click()
  }

  // 自定义字体格式
  // formats = [
  //   'Roboto',
  //   'Optima'
  // ];

  // 映射字体格式到CSS类名
  // formatClassMap = {
  //   'Optima': 'Optima', // 映射到CSS类名1
  //   'Roboto': 'Roboto', // 映射到CSS类名2
  // };

  // 自定义字体选项
  // fontOptions = [
  //   'Roboto',
  //   'Optima'
  // ];

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ align: [] }],
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link', 'image'],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // [{ 'font': [
      //   'Roboto',
      //   'Optima'
      // ] }],
      ['clean'],
    ],
    imageUpload: true
    // imageUploader: {
    //   upload: (file) => {
    //     // 在这里实现图片上传逻辑
    //     return new Promise((resolve, reject) => {
    //           console.log(file);
    //     });
    //   }
    // }
  }
  render() {
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d
        }}
      >
        <div
          className="btn"
          onClick={() => this.copyToClipboard()}
          style={{ width: '50px', height: '36px', lineHeight: '36px' }}
        >
          下载
        </div>
        <div style={{ padding: '30px' }}>
          <a
            id="downloadLink"
            style={{ display: 'none' }}
            download="downloaded_text.txt"
          >
            下载
          </a>
          <ReactQuill
            theme="snow"
            // formats={this.formats}
            style={{ height: '100vh' }}
            value={this.state.text}
            onChange={this.handleChange}
            modules={this.modules}
          />
        </div>
      </div>
    )
  }
}
