/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useEffect, useRef, useState } from 'react'

import { enquireScreen } from 'enquire-js'

import { Skeleton, message, Spin } from 'antd'
import MenuComponent from '../Component/Menu'

import FilterImg from '../Agix/FilterImg'

import './index.less'
message.config({
    top: 100
})

let isMobile
enquireScreen((b) => {
    isMobile = b
})

function App(props) {
    const [active, setActive] = useState('Explore')

    const [menuList, setMenuList] = useState([{
        name: 'Product',
        url: 'https://www.etnalabs.co/products'
    }, {
        name: 'Deepdive',
        url: 'https://www.etnalabs.co/research'
    }, {
        name: 'Contact Us',
        url: 'https://www.etnalabs.co/contact'
    }]);

    return (
        <div className="agix-content" >
            <div className="agix-temp0">
                <div className="agix-top-menu">
                    <MenuComponent isDark={true} menuList={menuList} />
                </div>
                <div className="agix-temp01" style={{ color: "#784AFF" }}>Products</div>
                <div className="agix-temp02">Building Up a Benchmark for the Al Revolution</div>
            </div>

            <div className="agix-content1"  >

                <div className="agix-temp1">Etna Capital provides sub-advisory services to Krane Shares's pioneering Artificial Intelligence & Technology ETF.</div>
                <div className="agix-temp2">
                    <span className="agix-temp21">The Global Artificial Intelligence & Technology ETF: </span>
                    As a distinctive vehicle for capturing global opportunities across the AGI value chain, the project primarily holds publicly listed global AI and technology companies covered by the Solactive Etna Artificial General Intelligence Index, designed to capture the performance of companies engaged in developing and applying artificial intelligence technologies. Beyond public companies, it also can allocate to private companies that are not included in its underlying index.
                </div>
                {/* <div className="agix-temp3">
                    AGlX primarily holds publicly listed global Al and technology companies and can allocate to privatecompanies that are not included in its underlying index.
                </div> */}

                <img src={"/agix/ball.png"} className='agix-temp6' alt="" />



                <div onClick={() => {
                    window.open('https://kraneshares.com/agix/', '_blank')
                }} className="agix-temp4">
                    Learn More
                </div>

                <div className="agix-temp5">
                    <img src={'/agix/logo1.png'} className='agix-temp51' alt="logo" />
                    <div className="agix-temp52">Get Your Portfolio AGI-ready</div>

                    <div style={{ marginTop: "30px" }}>
                        
                    <p>
                            Etna is an investment adviser registered under the Investment Advisers Act of 1940, as amended. Additional information about Etna is also available on the SEC’s Investment Adviser Public Disclosure website <span
                            onClick={()=>{
                                window.open('https://adviserinfo.sec.gov/firm/summary/330644', '_blank')
                            }} style={{ color: "#784AFF",cursor:"pointer",textDecoration:"underline" }}>here</span>.
                        </p>
                        
                        <p>
                            The KraneShares ETFs and KFA Funds ETFs are distributed by SEI Investments Distribution Company (SIDCO), 1 Freedom Valley Drive, Oaks, PA 19456, which is not affiliated with Krane Funds Advisors, LLC, the Investment Adviser for the Funds, or any sub-advisers for the Funds.
                        </p>
                        
                        <p>  Carefully consider the Funds’ investment objectives, risk factors, charges and expenses before investing. This and additional information can be found in the Funds’ full and summary prospectus, which may be obtained by visiting: <span onClick={()=>{
                            window.open('https://kraneshares.com/', '_blank')
                        }} style={{ color: "#784AFF",cursor:"pointer",textDecoration:"underline" }}>www.kraneshares.com</span>. Read the prospectus carefully before investing.</p>
                       
                        <p>
                            The opinions expressed herein are those of Etna Capital Management Mangement Ltd. (Etna) and are subject to change without notice. Material on this website is not financial advice or an offer to purchase or sell any product.
                        </p>
                        <p>
                            Past performance is not indicative of future results. Therefore, it should not be assumed that any specific investment or investment strategy made reference to directly or indirectly by Etna in its website, or indirectly via a link to an unaffiliated third-party website, will be profitable. Etna is not responsible for the content presented on third-party websites.
                        </p>
                     
                    </div>
                </div>



            </div>

        </div>

    )
}

export default App
