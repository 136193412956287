/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useEffect, useRef, useState } from 'react'

import { enquireScreen } from 'enquire-js'
import { DataSource } from '../data'
import Subscribe from '../Component/Subscribe'
import { get, post } from '../http'
import { Skeleton, message, Spin } from 'antd'
import { useParams, Link, useNavigate } from 'react-router-dom'
import MenuComponent from '../Component/Menu'
import ExploreComponent from './Explore'
import PhilosophyComponent from './Philosophy'
import CompaniesComponent from './Companies'
import ResourcesComponent from './Resources'
import FooterComponent from './Footer'
import MenuLeftComponent from "./Menu"
import './index.less'
message.config({
  top: 100
})

let isMobile
enquireScreen((b) => {
  isMobile = b
})

function App(props) {
  const clickHandler = useRef(false)
  const [active, setActive] = useState('Explore')
  const initMenuTop = useRef(0)
  const [menuList, setMenuList] = useState([{
    name: 'AGIX Index',
    url: '/'
  }, {
    name: 'Deepdive',
    url: 'https://www.etnalabs.co/research'
  }, {
    name: 'Contact Us',
    url: 'https://www.etnalabs.co/contact'
  }]);
  const menuHighlight = useRef([])
  const isFiexd = useRef(false)
  const optList = [{
    name: 'Explore',
  }, {
    name: 'Philosophy',
  }, {
    name: 'Companies',
  }, {
    name: 'Resources',
  }]





  useEffect(() => {

  }, [])

  useEffect(() => {

  }, [props])

  const handleChangeScroll = (item) => {
    initMenuTop.current = item
  }


  const changeHeight = () => {
    // const resizableDiv = document.getElementById('defaultCanvas0');
    const resizableDiv = document.getElementById('canvas-container');
    if (resizableDiv === null) return
    const initialHeight = 526;
    const minHeight = 272;
    const maxScroll = 500; // 你可以调整这个值
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    isFiexd.current = scrollTop > 3
    const scrollFraction = Math.min(scrollTop / maxScroll, 1);
    const newHeight = initialHeight - (initialHeight - minHeight) * scrollFraction;
    resizableDiv.style.height = `${newHeight}px`;

    const contentTitle = document.querySelectorAll('.agix-content_title');
    const contentTip = document.querySelector('.agix-content_tip');
    const contentTip1 = document.querySelector('.agix-content_tip1');
    const agixTopContent = document.querySelector('.agix-top-content')
    const agixTop = document.querySelector('.agix-top')
    // const agixTopLeft = document.querySelector('.agix-body_left')
    const agixTopLeftContent = document.querySelector('.agix-body_left_content')

    // const rect = agixTopLeft.getBoundingClientRect();
    // agixTopLeftContent.style.left = `${rect.left}px`;
    const canvasContent = document.querySelector('#defaultCanvas0')
    // const agixHeight = document.querySelector('.agix-top_h')
    // agixHeight.style.height = `${newHeight}px`;
    agixTop.style.height = `${newHeight}px`;
    const initialFontSizeTitle = 80;
    const minFontSizeTitle = 64;

    const initialFontSizeTip = 18;
    const minFontSizeTip = 16;

    const initialMarginTip = 18;
    const minMarginTip = 0;

    const initialFontSizeTip1 = 28;
    const minFontSizeTip1 = 24;

    const initialCanvasTransform = 1;
    const minCanvasTransform = 0.8;

    const initialCanvasMarginTop = 0;
    const minCanvasMarginTop = -100;


    const initialAgixTopLeftTop = initMenuTop.current;
    const minAgixTopLeftTop = minHeight + 50;
    // 调整字体大小
    const newFontSizeTitle = initialFontSizeTitle - (initialFontSizeTitle - minFontSizeTitle) * scrollFraction;
    contentTitle.forEach(el => el.style.fontSize = `${newFontSizeTitle}px`);

    const newFontSizeTip = initialFontSizeTip - (initialFontSizeTip - minFontSizeTip) * scrollFraction;
    const newMarginTip = initialMarginTip - (initialMarginTip - minMarginTip) * scrollFraction;
    contentTip.style.fontSize = `${newFontSizeTip}px`;
    contentTip.style.margin = `${newMarginTip}px 0px`;

    const newFontSizeTip1 = initialFontSizeTip1 - (initialFontSizeTip1 - minFontSizeTip1) * scrollFraction;
    contentTip1.style.fontSize = `${newFontSizeTip1}px`;


    const newCanvasTransform = initialCanvasTransform - (initialCanvasTransform - minCanvasTransform) * scrollFraction;
    const newCanvasMarginTop = initialCanvasMarginTop - (initialCanvasMarginTop - minCanvasMarginTop) * scrollFraction;
    canvasContent.style.transform = `scale(${newCanvasTransform})`;
    canvasContent.style.marginTop = `${newCanvasMarginTop}px`;
    const newTop = 50 - 50 * scrollFraction;
    const newMarginTop = -163.5 * (1 - scrollFraction);
    agixTopContent.style.top = `${newTop}%`;
    agixTopContent.style.marginTop = `${newMarginTop}px`;
    const newAgixTopLeftTop = initialAgixTopLeftTop - (initialAgixTopLeftTop - minAgixTopLeftTop) * scrollFraction;

    agixTopLeftContent.style.top = `${newHeight + 50}px`;

    // resizableDiv.style.position = scrollTop > 3?`fixed`:"absolute";
  }

  useEffect(() => {
    setTimeout(() => {
      // window.addEventListener('scroll', changeHeight)
    }, 1000)
    return () => {
      window.removeEventListener('scroll', changeHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  return (
    <>
      <div className="mobile-agix agix-content">
        <div className="agix-top-menu">
          <MenuComponent isDark={true} menuList={menuList} isMobile={isMobile} />
        </div>
        <div style={{ position: isFiexd.current ? 'fixed' : "", top: 0, zIndex: 999 }} className="agix-top">

          <div className="agix-top-content">
            <div>
              <div className="agix-content_title">Etna</div>
              <div className="agix-content_title">AGIX Index</div>
              <div className="agix-content_tip">Bloomberg Ticker: SOLEAGIX</div>
              <div className="agix-content_tip1">A Benchmark for the Al Revolution</div></div>
          </div>
        </div>

        <div className="agix-body">
          <MenuLeftComponent optList={optList} onChange={handleChangeScroll} />
          {/* <div  className="agix-top_h"></div> */}

          <div style={{ maxWidth: "100%" }}>
            <div id="Explore"><ExploreComponent /></div>
            <div id="Philosophy"><PhilosophyComponent /></div>
            <div id="Companies"><CompaniesComponent /></div>
            <div id="Resources"><ResourcesComponent /></div>

          </div>


        </div>
      </div>

     <div className="mobile-agix"> <FooterComponent /></div>
    </>

  )
}

export default App
