



import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import TableComponent from '../Table'
import { getTradeDay } from '../../utils'
import moment from 'moment';

dayjs.extend(utc);
dayjs.extend(timezone);


const App = ({ dataSource }) => {
  // // 设置时区为美东时间
  // const now = dayjs().tz('America/New_York');

  // // 获取Last Day
  // const lastDay = now.subtract(1, 'day').format('MMM DD, YYYY');

  // // 获取Last Week
  // const lastWeek = now.subtract(1, 'week').format('MMM DD, YYYY');

  // // 获取YTD（当前年第一天）
  // const YTD = now.startOf('year').format('MMM DD, YYYY');
  const now = dayjs().tz('America/New_York');
  const lastDay = useRef(now.subtract(1, 'day').format('YYYY-MM-DD'))
  const lastWeek = useRef(now.subtract(1, 'week').format('YYYY-MM-DD'))
  const YTD = useRef(now.startOf('year').format('YYYY-MM-DD'))
  const today = useRef(now.format('YYYY-MM-DD'))


  const [compareOptActive, setCompareOptActive] = useState("Last Day")
  const [list, setList] = useState([])
  const [sourceList, setSourceList] = useState({})
  const [columnsOptList, setColumnsOptList] = useState([{
    title: "Index",
    dataIndex: "index"
  }, {
    title: "Today",
    dataIndex: "today"
  }, {
    title: today.current,
    dataIndex: "date"
  }, {
    title: "Change（%）",
    dataIndex: "change"
  }])


  const compareOptList = ["Last Day", "Last Week", "YTD"]


  useEffect(() => {
    let timedate = lastDay.current

    if (compareOptActive === "Last Day") {
      timedate = lastDay.current
      setList(sourceList.lastDay || [])
    }

    if (compareOptActive === "Last Week") {
      timedate = lastWeek.current
      setList(sourceList.lastWeek || [])
    }

    if (compareOptActive === "YTD") {
      const year = moment(YTD.current).subtract(-1, 'year').format("YYYY-01-01");
      timedate = year
      setList(sourceList.YTD || [])
    }

    setColumnsOptList([{
      title: "Index",
      dataIndex: "index"
    }, {
      title: "Today",
      dataIndex: "today"
    }, {
      title: moment(timedate).format('ll'),
      dataIndex: "date"
    }, {
      title: "Change（%）",
      dataIndex: "change"
    }])
  }, [compareOptActive])


  const getSource = ({ todaydata, itemdata }) => {
    const t = todaydata ? todaydata.source : '-'
    const d = itemdata ? itemdata.source : '-'
    let c = '-'
    if (t !== '-' && d !== '-') {
      c = ((t - d) / d * 100).toFixed(2)
    }
    return {
      today: t,
      date: d,
      change: Number(c) > 0 ? `+${c}%` : `${c}%`
    }
  }

  useEffect(() => {
    const typs = ['SOLEAGIX', 'Nasdaq 100', 'S&P 500', 'Dow Jones']
    if (Object.keys(dataSource).length > 0) {
      today.current =dataSource.date.today
      lastDay.current = dataSource.date.lastDay
      lastWeek.current = dataSource.date.lastWeek
      YTD.current = dataSource.date.YTD
      
      const lastDayList = typs.map((item) => {
        const todaydata = dataSource.nowData.find(i => (i.type === item))
        const itemdata = dataSource.lastDayData.find(i => (i.type === item))
        const _values = getSource({ todaydata, itemdata })
        return {
          index: item,
          ..._values
        }
      })

      const lastWeekList = typs.map((item) => {
        const todaydata = dataSource.nowData.find(i => (i.type === item))
        const itemdata = dataSource.lastWeekData.find(i => (i.type === item))
        const _values = getSource({ todaydata, itemdata })
        return {
          index: item,
          ..._values
        }
      })

      const YTDList = typs.map((item) => {
        const todaydata = dataSource.nowData.find(i => (i.type === item))
        const itemdata = dataSource.YTDData.find(i => (i.type === item))
        const _values = getSource({ todaydata, itemdata })

        return {
          index: item,
          ..._values
        }
      })
      setSourceList({
        lastDay:lastDayList,
        lastWeek:lastWeekList,
        YTD:YTDList
      })
      setList(lastDayList)
      console.log('lastDayList', lastDayList);
      
    
    }
  }, [dataSource])



  return (<div className='explore-table'>
    <div className='explore-table_head'>
      <div className='explore-table_title'>Compare</div>
      <div className='explore-table_opt'>
        {compareOptList.map((item, index) => (<div onClick={() => setCompareOptActive(item)} className='explore-table_opt_item'>
          <span className={`explore-table_opt_itemtext ${compareOptActive === item ? "explore-table_opt_item_active" : ""}`}>{item}</span>
          {compareOptActive === item && <span className='explore-table_opt_itemline-active'></span>}
        </div>))}
      </div>
    </div>
    <TableComponent columns={columnsOptList} list={list} />

  </div>)
}



export default App