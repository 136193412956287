/**
 * @description base menu
 * @author wangfupeng
 */

import { DomEditor, IDomEditor, SlateEditor, SlateNode, SlateTransforms } from '@wangeditor/editor'

 class BaseMenu  {
    type = 'list-item-new'
    ordered=false;
    title='';
    iconSvg='';
    tag = 'button'

   getListNode(editor) {
    const { type } = this
    return DomEditor.getSelectedNodeByType(editor, type)
  }

  getValue(editor) {
    return ''
  }

  isActive(editor) {
    const node = this.getListNode(editor)
    if (node == null) return false
    const { ordered = false } = node 
    return ordered === this.ordered
  }

  isDisabled(editor) {
    if (editor.selection == null) return true

    const selectedElems = DomEditor.getSelectedElems(editor)
    const notMatch = selectedElems.some((elem) => {
      if (SlateEditor.isVoid(editor, elem) && SlateEditor.isBlock(editor, elem)) return true

      const { type } = elem 
      if (['pre', 'code', 'table'].includes(type)) return true
    })
    if (notMatch) return true

    return false
  }

  exec(editor, value) {
    const active = this.isActive(editor)
    if (active) {
      // 如果当前 active ，则转换为 p 标签
      SlateTransforms.setNodes(editor, {
        type: 'paragraph',
        ordered: undefined,
        level: undefined,
      })
    } else {
      // 否则，转换为 list-item
      SlateTransforms.setNodes(editor, {
        type: 'list-item-new',
        ordered: this.ordered, // 有序/无序
        indent: undefined,
      })
    }
  }
}

export default BaseMenu
